import { breakpoints } from 'src/styles/breakpoints';
import { orange, white } from 'src/styles/colors';
import styled from 'styled-components';

type ImageProsp = {
  image: string;
}

export const Section = styled.section`
  background: #FDF8EE ;

  .react-multiple-carousel__arrow--right {
    right: calc(50% - 144px);
  }

  .react-multiple-carousel__arrow--left {
    left: calc(50% - 144px);
  }

  .react-multi-carousel-dot {
    button{
      background-color: #FFDAB7 !important;
    }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: ${orange.extra} !important;
    }
  }

`
export const Card = styled.div`
  height: 276px;
  width: 100%;
  border-radius: 16px;
  padding: 22px;
  border: 1px solid #6A6C72;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url(${(props: ImageProsp) => props.image});
  background-size: cover;
  border: none;

  @media (min-width: ${breakpoints.md}){
    padding: 24px;
  }
  @media (min-width: ${breakpoints.lg}){
    padding: 24px 22px;
    height: 352px;
  }
  @media (min-width: ${breakpoints.xl}){
    width: 256px;
    height: 332px;
   padding: 24px, 16px, 24px, 16px;
  }
  @media (min-width: ${breakpoints.xxxl}){
    width: 100%;
    height: 332px;
   padding: 24px, 16px, 24px, 16px
  }
`

export const Button = styled.a`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${white};
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: end;
  align-items: center;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }
`
