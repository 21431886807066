import React, { useState } from 'react'
import vantagensJson from '../../assets/data/vantagensParaQuemInveste.json'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD, widths } from 'src/styles/breakpoints'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Qrcode from '../../assets/images/qrcode-home-investimentos.png'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'

import MaioresAltas from '@interco/icons/build-v4/bidis/v2/products/investimentos/renda-variavel/maiores-altas'
import Bank from '@interco/icons/build-v4/orangeds/MD/bank'
import Pig from '@interco/icons/build-v4/orangeds/MD/pig'
import ProductMany from '@interco/icons/build-v4/orangeds/MD/product-many'
import Wallet from '@interco/icons/build-v4/orangeds/MD/wallet'
import Cashback from '@interco/icons/build-v4/orangeds/MD/cashback'
import SocialWorker from '@interco/icons/build-v4/orangeds/MD/social-worker'
import Receipt from '@interco/icons/build-v4/orangeds/MD/receipt'
import * as URL from 'src/config/api/Urls'

import { Section } from './style'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaInvestimentos } = qrcodeInvestimentsData

type VantagensProps = {
  icon: string;
  text: string;
}

const VantagensParaQuemInveste = () => {
  const domReady = useDomReady()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const icons = {
    maioresAltas: <MaioresAltas height={24} width={24} color='#000000' />,
    pig: <Pig height={24} width={24} color='#000000' />,
    bank: <Bank height={24} width={24} color='#000000' />,
    productMany: <ProductMany height={24} width={24} color='#000000' />,
    wallet: <Wallet height={24} width={24} color='#000000' />,
    cashback: <Cashback height={24} width={24} color='#000000' />,
    socialWorker: <SocialWorker height={24} width={24} color='#000000' />,
    receipt: <Receipt height={24} width={24} color='#000000' />,
  }

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    element_action: 'click button',
    element_name: 'Quero conhecer',
    section_name: 'Investimentos que simplificam sua vida',
  }

  const intergoAppURL = 'https://inter-co.onelink.me/Qyu7/15kkin7y'

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={qrCodeAberturaDeContaInvestimentos.qrcode}
        dataLayer={dataLayer}
        asFilledLink={qrCodeAberturaDeContaInvestimentos.deeplink}
      />
    </Modal>
  )

  const handleIcon = (icon: string) => {
    switch (icon) {
      case 'maioresAltas':
        return icons.maioresAltas
      case 'pig':
        return icons.pig
      case 'bank':
        return icons.bank
      case 'productMany':
        return icons.productMany
      case 'wallet':
        return icons.wallet
      case 'cashback':
        return icons.cashback
      case 'socialWorker':
        return icons.socialWorker
      case 'receipt':
        return icons.receipt
      default:
        return icons.maioresAltas
    }
  }

  return (
    <Section
      className='d-flex justify-content-center align-items-center py-5'
      role='img'
      aria-label='Pessoa com smartphone na mão escolhendo tipos de investimentos'
    >
      {cardsModal}
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            {
              width < widths.md && (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/investments-dobra-5-mobile/image.webp'
                  altDescription='Pessoa com smartphone na mão escolhendo tipos de investimentos'
                  arrayNumbers={[ 309 ]}
                  arrayNumbersHeight={[ 155 ]}
                />
              )
            }
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-47 fw-700 text-grayscale--500 mb-4 mt-3 mt-md-0'>
              Vantagens para quem investe pelo Inter
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
              Aproveite as melhores oportunidades disponíveis no mercado.
            </p>
            {
              vantagensJson.map((item: VantagensProps) => (
                <div className='d-flex align-items-center mb-3 mb-md-3' key={item.text}>
                  {handleIcon(item.icon)}
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 ml-2 mb-0'>{item.text}</p>
                </div>
              ))
            }
            {
                width < WIDTH_MD
                ? (
                  <a
                    title='Quero conhecer'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
                    href={qrCodeAberturaDeContaInvestimentos.deeplink}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        section_name: 'Vantagens para quem investe pelo Inter',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                        redirect_url: qrCodeAberturaDeContaInvestimentos.deeplink,
                      })
                    }}
                  > Quero conhecer
                  </a>
                  )
                : (
                  <button
                    title='Quero conhecer'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        section_name: 'Vantagens para quem investe pelo Inter',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                      })
                    }}
                  >
                    Quero conhecer
                  </button>
                )
              }
          </div>
        </div>

      </div>
    </Section>
  )
}

export default VantagensParaQuemInveste
